<template>
  <v-container style="background: #EFF3F4" class="d-flex justify-center" :class="$vuetify.breakpoint.smAndDown ? 'pt-15 pb-10': ''">
    <v-row class="pa-0 up mx-4" justify="center" :class="$vuetify.breakpoint.smAndDown ? 'mt-15': 'mt-1 mb-5'">
      <v-col cols="12" md="11">
        <div
          v-for="(credit, indexdiv) in credits"
          :key="indexdiv"
          :class="smAndDown ? 'mb-5': 'my-5'"
        >
          <div class="section__text text-start mb-5">{{ credit.puesto }}</div>
          <div class="section d-flex align-center flex-wrap" :class="{'justify-space-around': $vuetify.breakpoint.smAndDown}">
            <div
              v-for="(person, indexCard) in credit.personas"
              :key="indexCard"
              class="card d-flex flex-column justify-center align-center mb-5 pt-5"
              :class="{'mr-5': !$vuetify.breakpoint.smAndDown}"
            >
              <v-img
                max-height="59"
                width="59"
                :src="require(`@/assets/creditos/${person.foto}`)"
                class="rounded-circle mx-auto"
              />
              <p class="card__text mx-5 mt-3 text-center">{{ person.nombre }}</p>
            </div>
          </div>
        </div>
        <div
          class="d-flex justify-start align-end"
          :style="{'height: 350px;': !smAndDown}"
        >
          <div class="card__text mb-0 mt-auto">
            Powered by <a style="text-decoration: none;" href="https://www.divelia.com/" target="_blank">Divelia</a>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'Creditos',
  data () {
    return {
      credits: [
        { puesto: 'Coordinador', personas: [{ nombre: 'Jeremy Soto', foto: 'jeremy.svg' }, { nombre: 'Javier Flores', foto: 'javier-flores.svg' }] },
        { puesto: 'Product Owner', personas: [{ nombre: 'Cristian Ycochea', foto: 'cristhian.svg' }] },
        { puesto: 'Developers', personas: [{ nombre: 'Cristian Ycochea', foto: 'cristhian.svg' }, { nombre: 'Jahir Moncada', foto: 'jahir.svg' }, { nombre: 'Patrick Tanta', foto: 'patrick.svg' }, { nombre: 'Bryan Baltazar', foto: 'bryan.svg' }] },
        { puesto: 'UI/UX Designer', personas: [{ nombre: 'Rodrigo Chambe', foto: 'rodrigo.svg' }, { nombre: 'Kathiusca Chapa', foto: 'kathiusca.svg' }] },
        { puesto: 'Beta Testers', personas: [{ nombre: '@med.datos', foto: 'med-datos.svg' }, { nombre: '@med.icando', foto: 'med-icando.svg' }, { nombre: '@drjuanca_11', foto: 'dr-juanca.svg' }, { nombre: '@sinapxis_med', foto: 'sinapxis-med.svg' }, { nombre: '@dr.rickys', foto: 'dr-rickys.svg' }] }
      ]
    }
  },
  methods: {
    totalCredits (index) {
      return this.credits.filter(item => item.col === index + 1)
    }
  },
  computed: {
    smAndDown () {
      return this.$vuetify.breakpoint.smAndDown
    }
  }
}
</script>

<style lang="scss" scoped>
.section {
  &__text {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #232323;
  }
}
.card {
  width: 130px;
  height: 144px;
  background: #FFFFFF;
  border: 1px solid #EFEFEF;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  &__text {
    font-size: 14px;
    line-height: 21px;
    // text-align: center;
    color: #232323;
  }
}
@media (max-width: 960px) {
  .up {
    margin-top: -13% !important;
  }
}
</style>
